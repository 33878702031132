import React from 'react'
import { useInView, type UseInViewOptions } from 'framer-motion'
import {
  trackEvent,
  type TrackingEventName,
  type TrackingProperties
} from '~/utils/tracking'

export function useTrackElementReached<T extends Element = HTMLDivElement>(
  eventName: TrackingEventName,
  properties?: TrackingProperties,
  useInViewOptions?: UseInViewOptions
) {
  const ref = React.useRef<T>(null)
  const isInView = useInView(ref, {
    once: true,
    ...useInViewOptions
  })

  const propertiesRef = React.useRef(properties)
  propertiesRef.current = properties

  React.useEffect(() => {
    if (isInView) {
      trackEvent(eventName, propertiesRef.current)
    }
  }, [eventName, isInView])

  return ref
}
